import { SlideShow, TagManager } from "@accor/ace-ui-core";

export default class MantraSlideShow extends SlideShow {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.bindTagmanager();
  }

  /**
   * set Event listener for the GA event
   */
  bindTagmanager() {
    const dataLayer = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    this.componentHost
      ?.querySelectorAll(
        ".cmp-slideshow__callout-section .cmp-slideshow__callout-item a",
      )
      ?.forEach((element) => {
        element?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          if (dataLayer && document.body.classList.contains("homepage")) {
            // Homepage
            // Push data to datalayer
            const datasEvent = {
              event: "GA4event",
              eventName: "bloc_interact",
              event_data: {
                pagename: "homepage",
                bloc_name: "discover all",
                bloc_interaction: "loyalty programme",
              },
            };
            TagManager.trackEvent(datasEvent);
          }
        });
      });
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  SlideShow.CLASS_NAMESPACE,
  MantraSlideShow,
  true,
);
