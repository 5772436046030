// Stylesheets
import "./theme.scss";
import "./resources/images/Android-chrome-192x192.png";
import "./resources/images/Android-chrome-512x512.png";
import "./resources/images/Apple-touch-icon-192x192.png";
import "./resources/images/favicon-16x16.png";
import "./resources/images/favicon-32x32.png";
import "./resources/images/mstile-150x150.png";

// ACE Library JS
import "@accor/ace-ui-core";

// COMMON JS Components
import "./components/common/callouts/v1/callout/js/callout.js";
import "./components/common/callouts/v1/callout-container/js/callout-container.js";
import "./components/common/core-booking-engine/v1/js/core-booking-engine.js";
import "./components/common/navigation/v1/js/navigation.js";
import "./components/common/core-booking-engine/v1/destination-dropdown/js/destination-dropdown";
// CONTENT JS Components
import "./components/content/highlight/v1/js/highlight.js";
import "./components/content/billboard/v1/js/billboard.js";
import "./components/content/card-editorial-slideshow/v1/js/card-editorial-slideshow.js";
import "./components/content/slideshow/v1/js/slideshow.js";
import "./components/content/nav-footer-list/v1/js/nav-footer-list.js";

document.dispatchEvent(
  new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT),
);
