import { Callout, TagManager } from "@accor/ace-ui-core";

export default class MantraCallout extends Callout {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /*
      Function to overwrite tagmanager for mantra
    */
  setCarouselClickListener() {
    const dataLayer = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayer) {
      let titleValue = "";
      if (this.title) {
        titleValue = this.title.innerText;
      }

      this.componentHost.addEventListener(
        CoreJS.DomEventConstants.CLICK,
        () => {
          const datasEvent = {
            event: "GA4event",
            eventName: "bloc_interact",
            event_data: {
              pagename: "homepage",
              bloc_name: "view more",
              bloc_interaction: titleValue?.toLowerCase()?.trim(),
              bloc_type: "destinations section",
            },
          };
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        },
      );
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  Callout.CLASS_NAMESPACE,
  MantraCallout,
  true,
);
