/**
 * @author Rishabh
 * @class MantraNavFooterList
 * @version 1.0
 * @inheritDoc
 */
import { NavFooterList, TagManager } from "@accor/ace-ui-core";

export default class MantraNavFooterList extends NavFooterList {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.bindTagmanager();
  }
  /**
   * set Event listener for the GA event
   */
  bindTagmanager() {
    const dataLayer = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayer && document.body.classList.contains("homepage")) {
      this.componentHost
        ?.querySelectorAll(
          ".ace-navfooterlist-accordion__panel .ace-navfooter-list__item a",
        )
        ?.forEach((element) => {
          element?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datalayerValue = JSON.parse(dataLayer);
            let page = datalayerValue.pageName;
            if (dataLayer && document.body.classList.contains("homepage")) {
              // Homepage
              page = "homepage";
            }
            // Push data to datalayer
            const datasEvent = {
              event: "GA4event",
              eventName: "bloc_interact",
              event_data: {
                pagename: page,
                bloc_name: element
                  ?.querySelector(".ace-navfooter-list__item-title")
                  ?.innerText?.toLowerCase()
                  ?.trim(),
                bloc_interaction: "footer",
              },
            };
            TagManager.trackEvent(datasEvent);
          });
        });
      const footerSubscribeCTA = document.querySelector(
        "footer .footercontainer .cta .ace-calltoaction a",
      );
      footerSubscribeCTA?.addEventListener(
        CoreJS.DomEventConstants.CLICK,
        () => {
          const datalayerValue = JSON.parse(dataLayer);
          let page = datalayerValue.pageName;
          if (dataLayer && document.body.classList.contains("homepage")) {
            // Homepage
            page = "homepage";
          }
          // Push data to datalayer
          const datasEvent = {
            event: "GA4event",
            eventName: "bloc_interact",
            event_data: {
              pagename: page,
              bloc_name: footerSubscribeCTA
                ?.querySelector(".ace-button__text")
                ?.innerText?.toLowerCase()
                ?.trim(),
              bloc_interaction: "footer",
            },
          };
          TagManager.trackEvent(datasEvent);
        },
      );
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  NavFooterList.CLASS_NAMESPACE,
  MantraNavFooterList,
  true,
);
