import { TagManager } from "@accor/ace-ui-core";

export default class MantraHighlight extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "ace-highlight";

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.bindTagmanager();
  }

  /**
   * Function to call tagmanager for highlight
   */
  bindTagmanager() {
    const layerElement = document.querySelector("[data-cmp-datalayer]");
    const dataLayer = layerElement?.getAttribute("data-cmp-datalayer");
    if (dataLayer) {
      const datalayerValue = JSON.parse(dataLayer);
      const page = datalayerValue.pageName.split("::")[2];
      const highlightButton = this.componentHost.querySelector(
        ".ace-highlight__content a",
      );
      if (page == "offers hub") {
        const offerName = this.componentHost.querySelector(
          ".ace-highlight__content--title",
        ).innerText;
        highlightButton?.addEventListener(
          CoreJS.DomEventConstants.CLICK,
          () => {
            const datasEvent = {
              event: "GA4event",
              eventName: "bloc_interact",
              event_data: {
                pagename: datalayerValue.pageName,
                bloc_name: "main offer",
                bloc_interaction: offerName?.toLowerCase()?.trim(),
              },
            };
            TagManager.trackEvent(datasEvent);
          },
        );
      } else if (page == "offers details page") {
        const hotelName = this.componentHost.querySelector(
          ".ace-highlight__content--title",
        ).innerText;
        const offerName = this.componentHost.querySelector(
          ".ace-highlight__kicker",
        ).innerText;
        highlightButton?.addEventListener(
          CoreJS.DomEventConstants.CLICK,
          () => {
            const datasEvent = {
              event: "GA4event",
              eventName: "bloc_interact",
              event_data: {
                pagename: datalayerValue.pageName,
                bloc_name: "main offer",
                bloc_interaction: offerName?.toLowerCase()?.trim(),
                bloc_type: hotelName?.toLowerCase()?.trim(),
              },
            };
            TagManager.trackEvent(datasEvent);
          },
        );
      }
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  MantraHighlight.CLASS_NAMESPACE,
  MantraHighlight,
);
